import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import eSearch from '../assets/images/esearch.png';
import symphony from '../assets/images/sympony.png';
import almirqab from '../assets/images/almirqab.jpg';
import vbh from '../assets/images/vbh.png';
import pulse from '../assets/images/pulse.png';
import frankentek from '../assets/images/frankentek.png';

const projects = [
  {
    title: 'Al Mirqab',
    description:
      'A high-tech yacht IT system featuring advanced security and access control.',
    imageUrl: almirqab,
    link: 'https://www.superyachttimes.com/yachts/al-mirqab',
  },
  {
    title: 'VBH Yacht Central',
    description: 'Automation of deployment of Cisco networks',
    imageUrl: vbh,
    link: 'https://www.vbhi.com/our-portfolio/highlighted-services/yacht-central.',
  },
  {
    title: 'Symphony',
    description:
      'An integrated IT infrastructure and IPTV system designed for luxury yachts.',
    imageUrl: symphony,
    link: 'https://www.feadship.nl/fleet/symphony',
  },
  {
    title: 'eSearch',
    description: 'A custom-built b2b search engine.',
    imageUrl: eSearch,
    link: 'https://www.esearch.nu/',
  },
  {
    title: 'VBH Pulse',
    description: 'Media delivery platform.',
    imageUrl: pulse,
    link: 'https://www.vbhi.com/our-portfolio/highlighted-products/pulse',
  },
  {
    title: 'Frankentek',
    description: 'Purpose-built Security System Software for Superyachts.',
    imageUrl: frankentek,
    link: 'https://yachtsecurity.com/',
  },
];

const ProjectsSection: React.FC = () => {
  return (
    <Box id="projects" className="section" sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h1" gutterBottom>
          Recent Projects
        </Typography>
        <Typography variant="h5" paragraph>
          Over the years, we have successfully delivered a range of innovative
          and high-impact projects. Here are some notable examples of our work:
        </Typography>

        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ maxWidth: 345 }}>
                <LazyLoadImage
                  alt={project.title}
                  effect="blur"
                  src={project.imageUrl}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {project.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {project.description}
                  </Typography>
                </CardContent>
                {project.link && (
                  <Button
                    size="small"
                    color="primary"
                    href={project.link}
                    target="_blank"
                  >
                    Learn More
                  </Button>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ProjectsSection;
