import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({ name: '', email: '', message: '' });
  const [status, setStatus] = useState<{
    message: string;
    isError: boolean;
  } | null>(null);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const newErrors = { name: '', email: '', message: '' };
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!formData.message) newErrors.message = 'Message is required';

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus(null);

    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch('https://formspree.io/f/xovanqjw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus({ message: 'Message sent successfully!', isError: false });
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatus({
          message: 'Failed to send message. Please try again later.',
          isError: true,
        });
      }
    } catch (error) {
      setStatus({
        message: 'Failed to send message. Please try again later.',
        isError: true,
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h4">Contact Us</Typography>
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextField
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
        error={!!errors.email}
        helperText={errors.email}
      />
      <TextField
        label="Message"
        name="message"
        multiline
        rows={4}
        value={formData.message}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
        error={!!errors.message}
        helperText={errors.message}
      />
      <Button type="submit" variant="contained" color="primary">
        Send
      </Button>
      {status && (
        <Typography
          variant="body1"
          sx={{
            color: status.isError ? 'error.main' : 'success.main',
            marginTop: '1rem',
          }}
        >
          {status.message}
        </Typography>
      )}
    </Box>
  );
};

export default Contact;
