import React from 'react';
import {
  Box,
  Typography,
  Container,
  Link,
} from '@mui/material';
import Contact from "./ContactForm";

const ContactSection: React.FC = () => {
  return (
    <Box id="contact" className="section" sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="h5" paragraph>
          We’d love to hear from you! Whether you have questions about our
          services, need support, or want to discuss a project, feel free to get
          in touch with us.
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" component="h3" gutterBottom>
            Contact Information
          </Typography>
          <Typography paragraph>
            <strong>3Folded B.V.</strong>
            <br />
            <Link href="mailto:vaniat@3folded.nl">vaniat@3folded.nl</Link>
            <br />
            <Link
              href="https://www.linkedin.com/in/vania-toperich-67059b2/"
              target="_blank"
              rel="noopener"
            >
              LinkedIn Profile
            </Link>
          </Typography>
        </Box>
        <Contact />
      </Container>
    </Box>
  );
};

export default ContactSection;
