import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const AboutSection: React.FC = () => {
  return (
    <Box id="about" className="section" sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          About Us
        </Typography>
        <Typography variant="h5" paragraph>
          Welcome to 3Folded B.V., a premier provider of comprehensive IT
          solutions based in Amsterdam. With over 30 years of experience, we
          specialize in IT Networking, IT Security, On-Premise Physical Security
          Solutions, Full Stack Application Development, and UI/UX Design. Our
          mission is to deliver innovative and effective solutions that meet the
          unique needs of our clients.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Our Expertise
        </Typography>
        <Typography paragraph>
          At 3Folded B.V., we pride ourselves on our deep industry knowledge and
          technical expertise. We offer a wide range of services designed to
          ensure the security, reliability, and efficiency of your IT
          infrastructure. Whether you need advanced network solutions, robust
          security measures, or custom application development, our team is
          equipped to handle it all.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Support Options
        </Typography>
        <Typography paragraph>
          Based in Amsterdam, we provide flexible support options to cater to
          your specific needs. Our services include both on-site and remote
          support, allowing us to offer timely assistance regardless of your
          location. Our dedicated team is available to address any issues,
          perform maintenance, and ensure that your systems operate smoothly.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Why Choose Us?
        </Typography>
        <Typography paragraph>
          Our commitment to excellence and client satisfaction sets us apart. We
          believe in a proactive approach to problem-solving, aiming to prevent
          issues before they arise. Our team works closely with clients to
          understand their needs, providing tailored solutions that drive
          success. With a strong focus on communication and collaboration, we
          ensure that every project is executed with precision and care.
        </Typography>
      </Container>
    </Box>
  );
};

export default AboutSection;
