import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const HomeSection: React.FC = () => {
  return (
    <Box id="home" className="section" sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Typography variant="h5" paragraph>
          At 3Folded B.V., we specialize in providing comprehensive solutions
          across several critical domains to ensure your business operates
          smoothly and securely. We offer expertise in IT Networking, IT
          Security, On-Premise Physical Security Solutions, Full Stack
          Application Development, and UI/UX Design.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom>
          Our Core Services
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          IT Networking
        </Typography>
        <Typography paragraph>
          We design and implement robust network infrastructures tailored to
          meet your organization's needs. Our services include setting up and
          optimizing network configurations, troubleshooting complex network
          issues, and ensuring seamless connectivity and reliability.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          IT Security
        </Typography>
        <Typography paragraph>
          Protecting your data and network is our top priority. We offer
          comprehensive IT security solutions including firewall configurations,
          intrusion detection systems, and secure access controls. Our expertise
          ensures that your digital assets are safeguarded against evolving
          threats.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          On-Premise Physical Security Solutions
        </Typography>
        <Typography paragraph>
          We provide advanced on-premise physical security systems to protect
          your physical assets. Our solutions include CCTV surveillance, access
          control systems, and integrated security platforms designed for
          various environments, including high-security areas.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Full Stack Application Development
        </Typography>
        <Typography paragraph>
          Our development team excels in building complete applications from
          front-end to back-end. Whether it's a web app or mobile app, we handle
          everything from initial concept and design to coding, deployment, and
          maintenance, ensuring a smooth and scalable solution.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          UI/UX Design
        </Typography>
        <Typography paragraph>
          Delivering a great user experience is essential for any application.
          We focus on creating intuitive and engaging user interfaces that
          enhance usability and satisfaction. Our design process includes user
          research, prototyping, and testing to ensure the best possible user
          experience.
        </Typography>
      </Container>
    </Box>
  );
};

export default HomeSection;
