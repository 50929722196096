import React from 'react';
import { Box, Typography, Container, List, ListItem } from '@mui/material';

const ServicesSection: React.FC = () => {
  return (
    <Box id="services" className="section" sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" gutterBottom>
          Our Services
        </Typography>
        <Typography variant="h5" paragraph>
          At 3Folded B.V., we offer a comprehensive range of IT and security
          solutions tailored to meet the needs of businesses of all sizes. Our
          expertise spans IT Networking, IT Security, Full Stack Development,
          and UI/UX Design. Each of our services is designed to provide maximum
          efficiency, security, and user satisfaction.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          IT Networking
        </Typography>
        <Typography paragraph>
          Our IT Networking services are designed to build and maintain robust,
          scalable, and secure network infrastructures. We leverage
          industry-leading technologies and brands to deliver reliable
          networking solutions that support your business operations.
        </Typography>
        <List>
          <ListItem>
            **Cisco**: Expertise in Cisco Identity Services Engine (ISE),
            Wireless LAN Controllers (WLC), and network automation using Netconf
            and YANG models.
          </ListItem>
          <ListItem>
            **Fortinet**: Implementation of Fortigate firewalls for
            comprehensive network security.
          </ListItem>
          <ListItem>
            **VMware**: Utilization of VMware for virtualization and network
            management.
          </ListItem>
        </List>
        <Typography paragraph>
          Our services include network design, installation, optimization, and
          troubleshooting to ensure seamless connectivity and performance.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          IT Security
        </Typography>
        <Typography paragraph>
          Protecting your IT infrastructure from threats is crucial. Our IT
          Security services provide a multi-layered approach to safeguard your
          systems, data, and applications.
        </Typography>
        <List>
          <ListItem>
            **Fortigate**: Deployment of Fortinet Fortigate firewalls to protect
            against external and internal threats.
          </ListItem>
          <ListItem>
            **Cisco ISE**: Implementing Cisco Identity Services Engine to manage
            secure access and network policies.
          </ListItem>
          <ListItem>
            **Palo Alto**: Utilizing Palo Alto firewalls and security solutions
            for advanced threat protection.
          </ListItem>
        </List>
        <Typography paragraph>
          We offer services such as firewall configuration, intrusion detection,
          vulnerability assessments, and compliance management to keep your data
          secure and your operations running smoothly.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Full Stack Application Development
        </Typography>
        <Typography paragraph>
          Our Full Stack Development services cover the entire development
          lifecycle, from initial concept to deployment and maintenance. We use
          modern frameworks and technologies to build scalable and efficient
          applications.
        </Typography>
        <List>
          <ListItem>
            **React**: Developing dynamic and responsive front-end interfaces.
          </ListItem>
          <ListItem>
            **Node.js**: Building server-side applications and APIs for seamless
            data processing and interaction.
          </ListItem>
          <ListItem>
            **Django**: Utilizing Django for robust back-end development and
            secure web applications.
          </ListItem>
          <ListItem>
            **Angular**: Leveraging Angular for creating high-performance web
            applications with a rich user experience.
          </ListItem>
          <ListItem>
            **Flask**: Implementing Flask for lightweight and flexible back-end
            services.
          </ListItem>
        </List>
        <Typography paragraph>
          Our approach includes requirement analysis, design, coding, testing,
          and deployment to deliver fully functional and user-friendly
          applications tailored to your business needs.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          UI/UX Design
        </Typography>
        <Typography paragraph>
          Great user experience (UX) and user interface (UI) design are
          essential for engaging and retaining users. Our approach to UI/UX
          design focuses on creating intuitive, visually appealing, and
          effective interfaces.
        </Typography>
        <Typography paragraph>
          We start with understanding user needs and business goals through
          research and user interviews. We then create wireframes and prototypes
          to visualize the user journey and design the interface. Our process
          involves iterative testing and feedback to refine and enhance the user
          experience. The end result is an interface that not only looks good
          but also performs seamlessly, providing a satisfying experience for
          your users.
        </Typography>
      </Container>
    </Box>
  );
};

export default ServicesSection;
